import { Suspense } from 'react'
import { ImageFillIcon } from '../../../assets/icons'
import { MSLLogo } from '../../../assets/images'
import { Outlet, useNavigate } from 'react-router-dom'
import { Typography } from '../../atoms/Typography'

const AuthLayout = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  return (
    <div className="h-screen w-screen bg-Base-Surface">
      <div className="absolute top-0 z-[1] w-full lg:w-1/2 ">
        <div className='blur-div'>
          <img alt="MSL Kopkara Auction" className="content z-[2] w-[118.55px] mx-auto cursor-pointer" src={MSLLogo} onClick={() => navigate('/')} />
        </div>
      </div>
      <div className="h-full w-full grid-cols-1 grid lg:grid-cols-2">
        <div className="relative bg-white overflow-y-auto hidden-scrollbar h-screen pt-[90px]">
          <div className="flex flex-col justify-between items-center space-y-8 h-full">
            <div className='flex items-center justify-center grow px-5 w-full'>
              <Suspense fallback={<div>Loading...</div>}>
                <Outlet />
              </Suspense>
            </div>
            <div className='flex space-x-2 pb-5'>
              <Typography
                color='secondary'
                size='md'
                text={`MSL © ${year}. All Right Reserved`}
              />
              <div
                className='pl-2 border-l border-Base-Inline font-semibold text-color-primary-500 cursor-pointer'
                onClick={() => navigate('/contact-us')}
              >
                Bantuan
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:flex items-center justify-center">
          <div className="text-center text-Base-Icon text-lg space-y-4">
            <ImageFillIcon className="mx-auto" />
            <p>
              Foto / gambar akan di upload melalui Admin Panel<br />
              (w: 720px, h: 900px)
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AuthLayout