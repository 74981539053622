import Footer from "../../organisms/Footer";
import { Suspense } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import Header from "../../organisms/Header";
import ModalSignIn from "../../organisms/ModalSignIn";
import LoadingPage from "../../../pages/loading-page";
import { useSelector } from "react-redux";

const MainLayout = () => {
  const loginModal = useSelector(state => state.loginModal)
  return (
    <div className="overflow-x-hidden">
      <Header />
      <div>
        <Suspense fallback={<LoadingPage />}>
          <Outlet />
        </Suspense>
        <Footer />
        <ScrollRestoration />
      </div>
      <ModalSignIn openModal={loginModal}/>
    </div>
  )
}

export default MainLayout;